import React from 'react';
import { Loader } from '@fortum/elemental-ui';
import axios from 'axios/index';
import toast from 'react-hot-toast';

class Logout extends React.Component {
  async componentWillMount() {
    let logoutSuccessEndpoint, logoutEndpoint;

    try{
      const configRes = await axios.get(`/api/auth/config-info/${window.localStorage.getItem('country')}`);
      const config = configRes.data;
      logoutSuccessEndpoint = config.logoutSuccess;
      logoutEndpoint = config.logoutEndpoint;

      const logoutRes = await axios.get(logoutEndpoint, {
        params: {
            id_token_hint: window.sessionStorage.getItem('id_token')
        }
      })
      if(logoutRes.status === 204){
        window.sessionStorage.clear();
        window.localStorage.clear();
        window.location.href = logoutSuccessEndpoint;
      }
    } catch(err){
      toast.error("Error logging out")
    }
    
    
  }

  render() {
    return (
      <div className="wrapper margin-top-large text-center margin-bottom-large">
        <Loader size={256} />
      </div>
    );
  }
}

export default Logout;
